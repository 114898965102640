import { collection, getDocs } from "firebase/firestore";
import EnvStore from "../../store/secretStore";

export async function fetchAllOrganizationDomains() {
  try {
    const organisationCollectionRef = collection(EnvStore.getState().db, "organisation");

    const snapshot = await getDocs(organisationCollectionRef);

    const domainMap = snapshot.docs.reduce((acc, doc) => {
      const domain = String(doc.data()?.domain);
      if (domain) {
        acc[domain] = doc.id; 
      }
      return acc;
    }, {});

    return domainMap;
  } catch (error) {
    console.error("Error fetching organization domains from Firestore:", error);
    throw error;
  }
}

