// src/components/header/ModeToggle.jsx
import React from "react";
import UIStore from "../../../store/uiStore";
import FileUploadStore from "../../../store/fileUploadStore";
import ChatStore from "../../../store/chatStore";
import { getChat } from "../../../utils/firestoreFunctions/getChat";
import { addCopyButtons } from "../../../utils/addCopyButtons";
import { useNavigate } from "react-router-dom";

export const ModeToggle = ({RemoveUpload, messageRef, scrollRef}) => {
  const { orgAvailable, setModeRef, setActiveMode, setShowDetails, activeMode } = UIStore();
  const { setFileUrl } = FileUploadStore();
  const { setMessages, setSideChat, setActiveChat, setAddChatDisabled } =
    ChatStore();
    const navigate = useNavigate();

  const changeActiveMode = (mode) => {
    setModeRef(mode);
    setFileUrl("");
    setActiveMode(mode);
    setAddChatDisabled(true);
    setSideChat([]);
    setMessages([]);
    setShowDetails(true);
    setActiveChat("");
    getChat(messageRef, scrollRef, addCopyButtons, false, RemoveUpload);
    navigate(`/chat`);
  };


  return (
    <div className="flex items-center space-x-2 sm:justify-center">
      {orgAvailable === true && (
        <>
          <span className="text-gray-200 text-xs sm:hidden xm:hidden xs:hidden xxs:hidden">Switch to:</span>
          <div className="flex items-center border-[#454E5A] border-[0.75px] opacity-100 rounded-full p-2">
            <button
              id="global"
              onClick={() => changeActiveMode("global")}
              className={`px-3 py-1 rounded-full text-xs font-medium transition-all duration-200 ${
                activeMode === "global"
                  ? "bg-[#F6AD55] text-white"
                  : "text-textGray hover:text-gray-300"
              }`}
            >
              Global
            </button>
            <button
              id="org"
              onClick={() => changeActiveMode("org")}
              className={`px-3 py-1 rounded-full text-xs font-medium transition-all duration-200 ${
                activeMode === "org"
                  ? "bg-[#F6AD55] text-white"
                  : "text-textGray hover:text-gray-300"
              }`}
            >
              Org Mode
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ModeToggle;
