import React, { useEffect, useState } from 'react';
import './App.css';
import AppRoutes from './routes/Routes';
import { useTheme } from './context/ThemeProvider';
import { initializeConfigFromAPI } from './api/Secret/Secret';

function App() {
  const [isLoad, setLoad] = useState(true);
  const { theme } = useTheme();

  useEffect(() => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = theme === 'dark' ? '/favicon.ico' : '/favicon_light.ico';
    }
  }, [theme]);

  useEffect(() => {
    initializeConfigFromAPI(() => {
      setLoad(false);
    });
  }, []);

  if (isLoad) return null;

  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
