import {create} from 'zustand';

const UIStore = create((set) => ({
  activeMode: "global",
  showSidebar: false,
  showDetails: true,
  placeHolder: "",
  recognitionRef: null,
  modeRef: "global",
  scrollRef: null,
  sideRef: null,
  idleTimeout : 10000,
  idleTimeoutRef: null,
  isRecording: false,
  orgAvailable: false,
  setShowDetails: (value) => set({ showDetails: value }),
  setIsRecording: (value) => set({ isRecording: value }),
  setActiveMode: (mode) => set({ activeMode: mode }),
  setShowSidebar: (value) => set({ showSidebar: value }),
  setPlaceHolder: (text) => set({ placeHolder: text }),
  setOrgAvailable : (value) => set({ orgAvailable : value}),
  setModeRef : (value) => set({modeRef : value})
}));

export default UIStore;
