import React, { useState, useRef, useEffect } from "react";
import { IoArrowUp } from "react-icons/io5";
import {
  Attachment,
  BlackAttachment,
  BlackCross,
  BlackMic,
  BlackMicActive,
  BlackPlus,
  CrossSvg,
  Mic,
  MicActive,
  Plus,
} from "../../../assets";
import { useTheme } from "../../../context/ThemeProvider";
import PromptStore from "../../../store/promptStore";
import { FileStore } from "../../../store/fileUploadStore";
import { FirebaseUpload } from "../../../utils/firestoreFunctions/firebaseUpload";
import { DeleteUploadedFile } from "../../../utils/firestoreFunctions/deleteFileFromDb";

const ChatInput = ({ send, chat_id }) => {
  const { theme } = useTheme();
  const { setPrompt, promptDisabled, prompt } = PromptStore();
  const [isAttachOpen, setIsAttachOpen] = useState(false);
  const [isStyleOpen, setIsStyleOpen] = useState(false);
  const [text, setText] = useState("");
  const [micActive, setMicActive] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);
  const attachDropdownRef = useRef(null);
  const styleDropdownRef = useRef(null);

  const { files } = FileStore();

  // const handleFileInputClick = () => {
  //   document.getElementById("chatFileInput").click();
  // };

  const handleFileInputClick = () => {
    setIsAttachOpen(false);
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const attachMenuItems = [
    {
      icon: theme === "dark" ? Attachment : BlackAttachment,
      label: "Upload File",
      onClick: handleFileInputClick,
    },
    // {
    //   label: "Choose from Drive",
    //   onClick: () => {
    //     setIsAttachOpen(false);
    //   },
    // },
  ];

  // Function to handle file selection and upload
  const handleFileUpload = (e) => {
    e.preventDefault();
    const file = e.target?.files?.[0];
    if (!file) {
      return;
    }
    // Allowed file extensions
    const allowedExtensions = ["pdf", "csv", "docx", "txt", "xlsx"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    // Check file extension
    if (!allowedExtensions.includes(fileExtension)) {
      console.log(
        "Invalid file type! Allowed types: pdf, csv, docx, txt, xlsx."
      );
      return;
    }

    const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
    if (fileSizeMB > 10) {
      alert("file size is greater than 10MB!"); // update to TOAST
      return;
    }

    // Check for duplicate files
    const isDuplicate = files.some(
      (uploadedFile) => uploadedFile.name === file.name
    );
    if (isDuplicate) {
      // toast.error("Duplicate file! This file has already been uploaded.");
      console.error("Duplicate file! This file has already been uploaded.");
      return;
    }

    const filePayload = {
      current: {
        files: [file],
      },
    };

    FirebaseUpload(chat_id, filePayload);
  };

  // Function to handle file removal
  const removeFile = async (file) => {
    DeleteUploadedFile(file?.file__path, chat_id);
  };

  useEffect(() => {
    let recognition = null;

    if ("webkitSpeechRecognition" in window) {
      recognition = new window.webkitSpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;

      recognition.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map((result) => result[0].transcript)
          .join("");
        setText((prev) => prev + transcript);
      };

      recognition.onerror = (event) => {
        console.error("Speech recognition error", event.error);
        setIsListening(false);
      };

      recognition.onend = () => {
        setIsListening(false);
      };
    }

    return () => {
      if (recognition) recognition.abort();
    };
  }, []);

  const toggleMic = () => {
    if (!text) {
      if ("webkitSpeechRecognition" in window) {
        if (isListening) {
          window.speechRecognition?.abort();
          setMicActive((prev) => !prev);
        } else {
          window.speechRecognition = new window.webkitSpeechRecognition();
          window.speechRecognition.start();
        }
        setIsListening(!isListening);
      } else {
        console.log("Speech recognition is not supported in this browser.");
      }
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "24px";
      const newHeight = Math.min(textarea.scrollHeight, 5 * 20);
      textarea.style.height = `${Math.max(24, newHeight)}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [prompt]);

  const styleMenuItems = [
    {
      label: "Normal",
      onClick: () => {
        console.log("Normal style selected");
        setIsStyleOpen(false);
      },
    },
    {
      label: "Professional",
      onClick: () => {
        console.log("Professional style selected");
        setIsStyleOpen(false);
      },
    },
  ];

  useEffect(() => {
    adjustTextareaHeight();
  }, [text]);

  const trimToWordLimit = (text, limit = 3000) => {
    const words = text.split(/\s+/).filter((word) => word.length > 0);
    return words.length > limit ? words.slice(0, limit).join(" ") : text;
  };

  const handleInputChange = (e) => {
    const inputText = e.target.value;
    setPrompt(trimToWordLimit(inputText));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check for Attach Dropdown
      if (
        attachDropdownRef.current &&
        !attachDropdownRef.current.contains(event.target) &&
        event.target !== fileInputRef.current // Ensure file input click doesn't close dropdown
      ) {
        setIsAttachOpen(false);
      }
      // Check for Style Dropdown
      if (
        styleDropdownRef.current &&
        !styleDropdownRef.current.contains(event.target)
      ) {
        setIsStyleOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="absolute bottom-4 sm:bottom-2 left-0 right-0 mx-auto w-[85%] max-w-3xl">
      <div className="dark:bg-[#353C4D] bg-white rounded-2xl px-3 sm:px-4 py-2 sm:py-3">
        {/* Hidden file input */}
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={handleFileUpload}
          accept="*/*"
          id="chatFileInput"
        />
        <div className="relative my-2">
          <div className="relative">
            <textarea
              placeholder="Enter your prompt here..."
              className="w-full bg-transparent dark:text-white text-[#0D3148] placeholder-gray-400 placeholder:italic px-3 pr-12 focus:outline-none text-xs sm:text-sm resize-none overflow-y-auto leading-5"
              ref={textareaRef}
              value={prompt}
              onChange={handleInputChange}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  if (prompt.trim() !== "") {
                    send();
                    setPrompt(""); // Clear prompt after sending
                    if (textareaRef.current) {
                      textareaRef.current.style.height = "24px"; // Reset height to single line
                    }
                  }
                }
              }}
              aria-label="Prompt input"
              style={{ height: "24px", minHeight: "24px" }}
            />
            <div className="absolute right-0 top-[-8px] bottom-0 w-12 flex items-center justify-center my-2">
              <button
                onClick={prompt ? send : toggleMic}
                className={`transition-colors ${
                  prompt
                    ? "text-amber-400 hover:text-amber-300"
                    : isListening
                    ? "text-red-400 animate-pulse"
                    : "text-emerald-400 hover:text-emerald-300"
                }`}
                disabled={!prompt.trim()}
                aria-label={text ? "Send message" : "Voice input"}
              >
                {prompt ? (
                  <div className="bg-amber-400 rounded-full p-2">
                    <IoArrowUp size={16} className="text-white" />
                  </div>
                ) : (
                  <>
                    {/* <div className="bg-amber-400 rounded-full p-2">
                      <IoArrowUp size={16} className="text-white" />
                    </div> */}
                    {micActive ? (
                      theme === "dark" ? (
                        <MicActive size={20} />
                      ) : (
                        <BlackMicActive size={20} />
                      )
                    ) : theme === "dark" ? (
                      <Mic size={20} />
                    ) : (
                      <BlackMic size={20} />
                    )}
                  </>
                )}
              </button>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-2 border-t border-[#CCCCCC] dark:border-[#454E5A] pt-2">
          <div className="flex gap-2 flex-shrink-0">
            {/* Attach Dropdown */}
            <div className="relative" ref={attachDropdownRef}>
              <button
                onClick={() => setIsAttachOpen(!isAttachOpen)}
                className={`flex items-center gap-2 px-3 sm:px-4 py-1.5 rounded-full transition-colors border ${
                  theme === "dark"
                    ? "bg-[#333341] hover:bg-[#3F3F4A] border-[#6D808D]"
                    : "bg-gray-200 hover:bg-gray-300 border-[#E5E5E5]"
                }`}
              >
                {theme === "dark" ? (
                  <Plus className="h-4" />
                ) : (
                  <BlackPlus className=" h-4" />
                )}
                <span className="hidden sm:inline text-[13px]">Attach</span>
              </button>

              {isAttachOpen && (
                <div
                  className={`absolute bottom-full mb-2 left-0 w-48 rounded-md shadow-lg z-50 ${
                    theme === "dark"
                      ? "bg-[#3F3F4A] border-[#454E5A]"
                      : "bg-white border-[#E5E5E5]"
                  }`}
                >
                  <div className="py-1">
                    {attachMenuItems.map((item, index) => (
                      <button
                        key={index}
                        onClick={item.onClick}
                        className={`w-full flex items-center px-4 py-2 text-[13px] ${
                          theme === "dark"
                            ? "text-gray-300 hover:bg-[#444858]"
                            : "text-gray-700 hover:bg-gray-100"
                        }`}
                      >
                        <item.icon
                          className="w-4 h-4 mr-3"
                          color={theme === "dark" ? "#37E8C5" : "#FF5B29"} // Dynamic icon color
                        />
                        {item.label}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* Respond Style Dropdown */}
            {/* <div className="relative hidden sm:block" ref={styleDropdownRef}>
              <button
                onClick={() => setIsStyleOpen(!isStyleOpen)}
                className={`flex items-center gap-2 px-3 sm:px-4 py-1.5 rounded-full transition-colors ${
                  theme === "dark"
                    ? "bg-[#2D3748] hover:bg-[#3A3E4C]"
                    : "bg-gray-200 hover:bg-gray-300"
                }`}
              >
                Respond Style
                <span className="ml-1">▼</span>
              </button>

              {isStyleOpen && (
                <div
                  className={`absolute bottom-full mb-2 left-0 w-48 rounded-md shadow-lg z-50 ${
                    theme === "dark"
                      ? "bg-[#252530] border-gray-700"
                      : "bg-white border-gray-300"
                  }`}
                >
                  <div className="py-1">
                    {styleMenuItems.map((item, index) => (
                      <button
                        key={index}
                        onClick={item.onClick}
                        className={`w-full text-left px-4 py-2 text-sm ${
                          theme === "dark"
                            ? "text-gray-300 hover:bg-[#444858]"
                            : "text-gray-700 hover:bg-gray-100"
                        }`}
                      >
                        {item.label}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div> */}

            <div className="border-r border-[#CCCCCC] dark:border-[#454E5A]"></div>
          </div>

          {/* Files display section */}
          <div className="flex-grow overflow-x-auto hide-scrollbar">
            <div
              className="flex gap-2 items-center"
              style={{ width: "max-content" }}
            >
              {files?.length
                ? files.map((file, index) => (
                    <div
                      key={index}
                      className={`flex items-center gap-2 px-3 border py-1 rounded-full min-w-fit text-xs ${
                        theme === "dark"
                          ? "bg-[#454E5A] border-[#6D808D] text-[#CCD6F6]"
                          : "bg-gray-200 border-[#E5E5E5] text-[#0D3148]"
                      }`}
                    >
                      <span className="text-xs sm:text-sm max-w-[150px] truncate">
                        {file.name}
                      </span>
                      <button
                        onClick={() => removeFile(file)}
                        className="text-gray-400 hover:text-gray-200 transition-colors flex-shrink-0"
                        aria-label={`Remove ${file}`}
                      >
                        {theme === "dark" ? (
                          <CrossSvg className="h-4" />
                        ) : (
                          <BlackCross className="h-4" />
                        )}
                      </button>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatInput;
