import axios from 'axios';
import CryptoJS from 'crypto-js';
import { initializeConfig, secretKey } from '../../constants/const';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import EnvStore from '../../store/secretStore';

export function decryptData(encrypted_data, iv_data) {

    const encryptedData = CryptoJS.enc.Base64.parse(encrypted_data);
    const iv = CryptoJS.enc.Base64.parse(iv_data);
    const SECRET_KEY = CryptoJS.enc.Utf8.parse(secretKey);

    const decryptedData = CryptoJS.AES.decrypt(
        { ciphertext: encryptedData },
        SECRET_KEY,
        { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );

    const jsonData = decryptedData.toString(CryptoJS.enc.Utf8);

    // console.log("Decrypted JSON Data:", jsonData);
    return JSON.parse(jsonData); // Return parsed JSON
}


export async function getSecret() {
    try {
        const api = process.env.REACT_APP_API_URL + "/get_secret";
        const response = await axios.get(api);
        return response.data; // Return the decrypted secret
    } catch (error) {
        console.error('Error fetching secret:', error);
    }
}

export async function initializeConfigFromAPI(callback) {
    const { setSecret } = EnvStore.getState();
    try {
        // Fetch and store secret if not in localStorage
        if (!localStorage.getItem("secret") || !localStorage.getItem("iv")) {
            const secret = await getSecret();
            if (!secret || !secret.encrypted_data || !secret.iv) {
                throw new Error("Invalid secret data");
            }
            localStorage.setItem("secret", secret.encrypted_data);
            localStorage.setItem("iv", secret.iv);
        }

        // Decrypt data
        const decryptedData = decryptData(
            localStorage.getItem("secret"),
            localStorage.getItem("iv")
        );

        if (!decryptedData) {
            throw new Error("Failed to decrypt data");
        }

        // Initialize configuration
        initializeConfig(decryptedData);

        // Firebase configuration
        const firebaseConfig = {
            apiKey: decryptedData["API_KEY"],
            authDomain: decryptedData["AUTHDOMAIN"],
            projectId: decryptedData["PROJECT_ID"],
            storageBucket: decryptedData["STORAGE_BUCKET"],
            messagingSenderId: decryptedData["MESSAGE_ID"],
            appId: decryptedData["APP_ID"]
        };

        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app, decryptedData["DB_ID"]);
        const storage = getStorage(app, `gs://${decryptedData["STORAGE_BUCKET"]}`);
        const auth = getAuth(app);
        const googleProvider = new GoogleAuthProvider();

        // Set secret in the state store
        setSecret({
            app,
            db,
            storage,
            auth,
            googleProvider,
            callback: callback,
        });
    } catch (error) {
        console.error("Error initializing the app:", error);
    }
};
