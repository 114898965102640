import axios from "axios";
import { collection, doc, query, where, getDocs } from 'firebase/firestore';
import EnvStore from "../../store/secretStore";
import { getConfig } from '../../constants/const';

export const updateUserDocumentId = async (targetEmail, newDocId, orgId) => {
  const { API_URL } = getConfig();
  try {
    const usersRef = collection(
      doc(collection(EnvStore.getState().db, "organisation"), orgId),
      "users"
    );

    // Query to find the user document by email
    const q = query(usersRef, where("email", "==", targetEmail));
    const querySnapshot = await getDocs(q);
    const api = API_URL + "/migrate";

    if (!querySnapshot.empty) {
        const oldDoc = querySnapshot.docs[0];
        const newDocRef = doc(usersRef, newDocId);

        const requestBody = {
          source: `organisation/synapses/users/${oldDoc.id}`,
          destination: `organisation/synapses/users/${newDocId}`,
      };
      
      try {
          await axios.post(api, requestBody);
          console.log("User document ID updated successfully!");

      } catch (error) {
          console.error("API Error:",error);
      }

      return newDocRef; // Return the new document reference
    } else {
      console.log("No user document found with the specified email.");
      return null;
    }
  } catch (error) {
    console.error("Error updating user document ID:", error);
    throw error;
  }
};
