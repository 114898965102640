import React from "react";
import UIStore from "../../../store/uiStore";
import { DocumentDropZone, FileUploadZone } from "./DocumentZone";
import { useTheme } from "../../../context/ThemeProvider";

const UploadSection = ({chat_id}) => {
  const { modeRef } = UIStore();
  const { theme } = useTheme();
  return (
    <>
      {modeRef && modeRef === "global" ? (
        <>
          <div className="lg:flex sm:hidden xm:hidden xs:hidden xxs:hidden dark:bg-dropShadow bg-white rounded-lg p-3 h-44 max-w-3xl mx-auto">
            <DocumentDropZone theme={theme} chat_id={chat_id} />
            <FileUploadZone theme={theme} chat_id={chat_id} />
          </div>
        </>
      ) : (
        <> </>
      )}
    </>
  );
};


export default UploadSection;
