import { ref, deleteObject } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import UserAuthStore from "../../store/userStore";
import EnvStore from "../../store/secretStore";
import { FileStore } from "../../store/fileUploadStore";

export const DeleteUploadedFile = async (file__path, chat_id) => {
  const files = FileStore.getState().files;
  const new_files = files.filter(file => file.file__path !== file__path);
  const fileRef = ref(EnvStore.getState().storage, file__path);
  const { user, orgId } = UserAuthStore.getState()
  
  if (!file__path) {
    const userDocRef = doc(EnvStore.getState().db, "organisation", orgId, "users", user?.uid, "chats", chat_id);
    await updateDoc(userDocRef, {
      files: new_files
    });
    FileStore.setState({files: new_files})
    return 
  }


  deleteObject(fileRef)
    .then(async () => {
      const userDocRef = doc(EnvStore.getState().db, "organisation", orgId, "users", user?.uid, "chats", chat_id);

        await updateDoc(userDocRef, {
          files: new_files
        });
        FileStore.setState({files: new_files})
    })
    .catch((error) => {
      console.error("Error deleting file:", error);
    });
}