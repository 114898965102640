import {create} from 'zustand';

const UserAuthStore = create((set) => ({
  user: {},
  email: localStorage.getItem("email"),
  orgId : "",
  setUser: (user) => set({ user }),
  setEmail: (email) => set({ email }),
  setOrgId: (value) => set({ orgId : value })
}));

export default UserAuthStore;
