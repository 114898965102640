import React, { useEffect, useRef, useState } from "react";
import { Copy, Dislike, Dot, Like, User } from "../../../assets";
import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import SmaLogo from "../../../assets/images/SmallLogo.png";
import SmaLogoLight from "../../../assets/images/SmaLogoLight.png";
import { FaArrowsRotate } from "react-icons/fa6";

const MarkdownRenderer = ({ content }) => (
  <ReactMarkdown
    className="prose prose-invert max-w-none leading-5 font-sans text-xs sm:text-[15px]"
    rehypePlugins={[rehypeHighlight]}
    components={{
      p: ({ children }) => (
        <p className="my-1.5 sm:my-3 leading-5">{children}</p>
      ),
      code: ({ inline, children, ...props }) =>
        inline ? (
          <code className="px-1 py-0.5 rounded text-xs font-mono" {...props}>
            {children}
          </code>
        ) : (
          <div className="bg-[#F0F4F9] dark:bg-[#23241f] flex-wrap rounded-md p-1.5 sm:p-4 my-1.5 overflow-x-auto">
            <code className="text-xs font-mono leading-5" {...props}>
              {children}
            </code>
          </div>
        ),
    }}
  >
    {content}
  </ReactMarkdown>
);

const Actions = ({
  regenFunc,
  chatId,
  handlePrevious,
  handleNext,
  disablePrevious,
  disableNext,
  currentIndex,
  totalAnswers,
}) => (
  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-1.5 sm:gap-0">
    <div className="gap-4 mt-2 hidden">
      <Copy />
      <Like />
      <Dislike />
      <Dot />
    </div>

    <div className="flex gap-1.5 items-center w-full sm:w-auto">
      {totalAnswers > 1 && (
        <div className="flex items-center gap-1.5 sm:gap-4">
          <button
            className={`text-gray-400 p-0.5 sm:p-0 ${
              disablePrevious
                ? "cursor-not-allowed opacity-50"
                : "cursor-pointer hover:text-gray-600 dark:hover:text-gray-300"
            }`}
            onClick={handlePrevious}
            disabled={disablePrevious}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-3.5 h-3.5 sm:w-5 sm:h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </button>

          <span className="text-gray-400 font-medium text-xs sm:text-base">
            {currentIndex + 1}/{totalAnswers}
          </span>

          <button
            className={`text-gray-400 p-0.5 sm:p-0 ${
              disableNext
                ? "cursor-not-allowed opacity-50"
                : "cursor-pointer hover:text-gray-600 dark:hover:text-gray-300"
            }`}
            onClick={handleNext}
            disabled={disableNext}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-3.5 h-3.5 sm:w-5 sm:h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </button>
        </div>
      )}
      {totalAnswers > 0 && (
        <div
          className="flex items-center gap-1 text-xs rounded-lg dark:bg-[#444858] bg-[#FFEAE6] p-1 sm:p-2 mb-1.5 cursor-pointer hover:opacity-80 transition-opacity ml-auto"
          onClick={() => regenFunc(chatId)}
        >
          <FaArrowsRotate className="dark:text-[#37E8C5] text-[#0D3148] w-3 h-3 sm:w-4 sm:h-4" />
          <span className="dark:text-[#37E8C5] text-[#0D3148] text-xs">
            Regenerate
          </span>
        </div>
      )}
    </div>
  </div>
);

const OneMessage = ({
  index,
  chat,
  user,
  isLatest,
  loading,
  theme,
  regenFunc,
}) => {
  const messageRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(chat?.currentIndex ?? 0);
  const currentAnswer = chat.answers[currentIndex];

  useEffect(() => {
    setCurrentIndex(chat?.currentIndex ?? 0);
  }, [chat?.currentIndex]);

  useEffect(() => {
    const chatArea = document.getElementById("chat-messages");
    if (chatArea && messageRef.current) {
      const isAtBottom =
        chatArea.scrollHeight - chatArea.scrollTop === chatArea.clientHeight;
      if (isAtBottom) {
        messageRef.current.style.marginBottom = "8px";
      } else {
        messageRef.current.style.marginBottom = "0";
      }
    }
  }, [chat]);

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chat]);

  const handleNext = () => {
    if (currentIndex < chat.answers.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <div className="mt-1.5" ref={messageRef} key={chat?.content?.length}>
      {isLatest && loading ? (
        <div className="w-3.5 h-3.5 border-2 border-t-2 border-t-green-400 border-gray-300 rounded-full animate-spin" />
      ) : (
        <div className="border-b border-gray-300 dark:border-gray-700 sm:mx-8 max-w-full sm:max-w-3xl xm:max-w-xl">
          <div
            className="rounded-full bg-gray-200 flex items-center justify-center border-b-2 border-gray-300 dark:border-gray-700 mt-2"
            style={{ height: "32px", width: "32px" }}
          >
            <img
              src={user.photoURL ? user.photoURL : User}
              alt="User Icon"
              className="rounded-full"
              style={{ width: "35px", objectFit: "cover" }}
            />
          </div>

          <div className="message rounded-lg dark:bg-[#30303D] dark:text-white bg-white text-[#0D3148] px-2 sm:px-4 py-1 mt-2 w-full">
            <MarkdownRenderer content={chat.content} />
          </div>

          <div
            className="rounded-lg dark:bg-[#30303D] bg-white flex items-center justify-center relative mt-5"
            style={{ height: "32px", width: "32px" }}
          >
            <img
              src={theme === "dark" ? SmaLogo : SmaLogoLight}
              alt="Bot Icon"
              style={{ width: "18px", objectFit: "cover" }}
            />
          </div>

          <div
            key={index}
            className="message rounded-lg dark:bg-[#30303D] dark:text-white bg-white text-[#0D3148] px-2 sm:px-4 py-1 my-2 w-full"
          >
            <MarkdownRenderer content={currentAnswer.content} />
            <Actions
              regenFunc={regenFunc}
              chatId={chat.id}
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              disablePrevious={currentIndex === 0}
              disableNext={currentIndex === chat.answers.length - 1}
              currentIndex={currentIndex}
              totalAnswers={chat.answers.length}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default OneMessage;
