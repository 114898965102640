import React, { useEffect } from 'react'

const MessageAlert = () => {

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const message = urlParams.get('message');
        if (message) {
          const messageDiv = document.getElementById('messageAlert');
          messageDiv.textContent = decodeURIComponent(message);
          messageDiv.style.display = 'block';
    
          setTimeout(() => {
            messageDiv.style.display = 'none';
          }, 2000);
        }
      }, []);
  return (
    <div
      id="messageAlert"
      className="fixed top-3 left-3 bg-blue-500 text-white p-3 rounded w-64 text-sm hidden"
      role="alert"
    ></div>
  )
}

export default MessageAlert