import React from 'react';
import { useTheme } from '../../context/ThemeProvider';

const OAuthButton = ({ onSignIn }) => {
  const { theme } = useTheme();

  return (
    <div
      className={`flex justify-center items-center shadow-md rounded-lg border-b-4 max-w-80 min-w-[260px] w-full h-32 cursor-pointer mx-20 ${
        theme === 'dark'
          ? 'bg-[#353540] border-teal-400'
          : 'bg-white border-[#FE5B29]'
      }`}
      onClick={onSignIn}
    >
      <div className="flex items-center justify-center bg-white px-4 py-2 rounded-full max-w-52 min-w-40 w-full h-10 border-[#999999] border">
        <img
          src={require('../../assets/images/google-logo.svg').default}
          alt="Google Logo"
          className="h-5 mr-2"
        />
        <span className="text-black text-center text-sm whitespace-nowrap">
          Sign in with Google
        </span>
      </div>
    </div>
  );
};

export default OAuthButton;
