import React from "react";
import { useTheme } from "../../context/ThemeProvider";

const Footer = () => {
  const { theme } = useTheme();
  return (
    <>
      <div
        className={`absolute left-0 bottom-5 sm:text-xs text-center w-full text-[10px] ${
          theme === "dark" ? "text-[#9FABCA]" : "text-[#666666]"
        }`}
      >
        PhotonAI © 2024. Powered with&nbsp;
        <span
          className={`${
            theme === "dark" ? "text-[#37E8C5]" : "text-[#FF5B29]"
          }`}
        >
          Synapse Xtreme Engine (SXE)
        </span>
      </div>
    </>
  );
};

export default Footer;
