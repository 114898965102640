import { doc, getDoc } from "firebase/firestore";
import UIStore from "../../store/uiStore";
import UserAuthStore from "../../store/userStore";
import EnvStore from "../../store/secretStore";
import { logout } from "../../api/logout";


export const getModeAuth = async () => {

  const {user , orgId} = UserAuthStore.getState()
  const { setOrgAvailable } = UIStore.getState();

  if(!user){
    logout()
    return
  }

  try {

    const orgRef = doc(EnvStore.getState().db, "organisation", orgId);
    const orgDocSnap = await getDoc(orgRef);
    const orgData = orgDocSnap.data();

    if (orgData.org_mode === false) {
      setOrgAvailable(false)
    } else {
      const chatDocRef = doc(EnvStore.getState().db, "organisation", orgId, "users", user?.uid);
      const chatDocSnap = await getDoc(chatDocRef);
      const data = chatDocSnap.data();
      if (data.org_mode === false) {
        setOrgAvailable(false)
      } else {
        setOrgAvailable(true)
      }
    }
  } catch (error) {
    console.error("Error retrieving or updating document:", error);
  }
};