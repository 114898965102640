import { collection, query, orderBy, getDocs, limit } from "firebase/firestore";
import EnvStore from "../../store/secretStore";
import UserAuthStore from "../../store/userStore";

// export async function loadChatMessages(chat_id) {
//   try {
//     const { db } = EnvStore.getState();
//     const { orgId, user } = UserAuthStore.getState();
//     if (!user) {
//       console.error("User is not authenticated.");
//       return [];
//     }

//     // Fetch messages
//     const messagesCollectionRef = collection(
//       db,
//       "organisation",
//       orgId,
//       "users",
//       user?.uid,
//       "chats",
//       chat_id,
//       "messages"
//     );

//     const messagesQuery = query(messagesCollectionRef, orderBy("timestamp", "asc"));
//     const querySnapshot = await getDocs(messagesQuery);

//     const msgData = querySnapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));

//     return msgData.length > 0 ? msgData : [];
//   } catch (error) {
//     console.error("Error fetching messages from Firestore:", error);
//     return [];
//   }
// }

export async function loadFirstChatWithMessageCount() {
  try {
    const { db } = EnvStore.getState();
    const { orgId, user } = UserAuthStore.getState();
    if (!user) {
      console.error("User is not authenticated.");
      return null;
    }

    // Reference to the chats collection
    const chatsCollectionRef = collection(
      db,
      "organisation",
      orgId,
      "users",
      user?.uid,
      "chats"
    );

    // Fetch the first chat
    const chatsQuery = query(chatsCollectionRef, orderBy("timestamp", "desc"), limit(1));
    const chatsSnapshot = await getDocs(chatsQuery);

    if (chatsSnapshot.empty) {
      console.log("No chats found.");
      return null;
    }

    // Get the first chat document
    const firstChatDoc = chatsSnapshot.docs[0];
    const chatId = firstChatDoc.id;

    // Reference to the messages collection for the first chat
    const messagesCollectionRef = collection(
      db,
      "organisation",
      orgId,
      "users",
      user?.uid,
      "chats",
      chatId,
      "messages"
    );

    // Fetch all messages in the first chat
    const messagesSnapshot = await getDocs(messagesCollectionRef);
    const messageCount = messagesSnapshot.size;
    return {
      chatId,
      messageCount,
    };
  } catch (error) {
    console.error("Error loading the first chat with message count:", error);
    return null;
  }
}