import React from "react";

const Loader = () => {
  // return (
  // <div className="flex justify-center items-center space-x-2">
  //   <span className="w-8 h-8 border-4 border-t-blue-500 border-solid rounded-full animate-spin"></span>
  //   <p className="text-white">Loading...</p>
  // </div>

  const loaderStyle = {
    border: "4px solid transparent",
    borderTopColor: "#3b82f6", // Tailwind's blue-500 color
    borderRadius: "50%",
    width: "3rem",
    height: "3rem",
    animation: "spin 1s linear infinite",
  };

  return (
    <div style={loaderStyle}>
      <style>
        {`
            @keyframes spin {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
          `}
      </style>
    </div>
  );
};

//   );
// };

export default Loader;
