import { LightMagic, Magic } from "../../../assets";
import { useTheme } from "../../../context/ThemeProvider";
import UIStore from "../../../store/uiStore";
import UserAuthStore from "../../../store/userStore";
import Dark_Icon from "../../../assets/images/Dark_Icon.png";
import LightLogoIcon from "../../../assets/images/LightLogoIcon.png";

export const WelcomeSection = () => {
  const { user } = UserAuthStore();
  const { orgAvailable, modeRef } = UIStore();
  const { theme } = useTheme();
  return (
    <>
      <div className="text-center mb-16 pt-12 md:mt-5">
        {/* Mobile version: Logo */}
        <div className="lg:hidden mb-4 items-center justify-center flex">
        {theme === "dark" ? (
          <img src={Dark_Icon} alt="Photon Logo" className="w-16 h-16" />
        ) : (
          // to replace the logo with the icon
          <img src={LightLogoIcon} alt="Logo" className="w-16 h-16" />
        )}
          
        </div>

        {/* Greeting */}
        <h1 className="dark:text-textDarkGreen text-[#778898]  lg:text-2xl md:text-lg sm:text-lg mb-3 font-semibold">
          {user?.displayName ? `Hi, ${user?.displayName}` : "Hi,"}
        </h1>

        {/* Assistance text */}
        <h2 className="dark:text-white text-[#0D3148] text-xl md:text-xl lg:text-2xl mb-3 font-bold">
          Can I help you with anything?
        </h2>

        {/* Description */}
        <p className="dark:text-textGray text-[#6D808D] text-sm lg:text-sm md:text-sm mt-2 text-center">
          Photon is ready to assist you with anything you need, including <br className="hidden md:inline" />
          queries answered and support for your regular work tasks.
        </p>
      </div>

      {orgAvailable && (
        <div
          id={modeRef === "org" ? "org-txt" : "global-txt"}
          className="dark:bg-dropShadow bg-white rounded-lg p-4 mb-8 md:mb-6"
        >
          <div className="flex flex-col md:flex-row md:items-center md:gap-2">
            <div className="flex items-center gap-2 mb-2 md:mb-0">
              {theme === "dark" ? <Magic /> : <LightMagic />}
              <span className="font-semibold text-base md:text-sm">
                Organisation mode:
              </span>
            </div>
            <span className="text-textGray text-sm md:text-xs lg:text-base md:mt-0">
              {modeRef === "org"
                ? "Enter your prompt and get direct results from your organisation database."
                : "Switch 'Org mode' to get direct results from your organisation database."}
            </span>
          </div>
        </div>
      )}
    </>
  );
};
