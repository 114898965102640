import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { FileStore } from "../../store/fileUploadStore";
import UserAuthStore from "../../store/userStore";
import EnvStore from "../../store/secretStore";
import { logout } from "../../api/logout";

function convertFileToObject(file, file__path, downloadURL) {
  return {
    name: file.name,
    size: file.size,
    type: file.type,
    file__path,
    downloadURL,
    uploadedAt: new Date().toISOString()
  };
}

export const FirebaseUpload = async (chat_id, fileInputRef) => {
  const { user, orgId } = UserAuthStore.getState();
  const { files, addFile, setFileLoading, setProgress } = FileStore.getState();

  // Reset progress at the start
  setFileLoading(true);
  setProgress(0);

  if (!user) {
    logout();
    return;
  }

  try {
    const file = fileInputRef.current.files[0];

    if (!file) {
      throw new Error("No file provided for upload.");
    }

    // Generate unique file path
    const uniqueFileName = `${Date.now()}_${file.name}`;
    const emailBeforeAt = user.email.split("@")[0];
    const file__path = `${orgId}/users/${emailBeforeAt}/${uniqueFileName}`;

    // Create storage reference
    const storageRef = ref(EnvStore.getState().storage, file__path);
    const uploadTask = uploadBytesResumable(storageRef, file, {
      chunkSize: 256 * 1024 // 256KB chunks for more frequent updates
    });
    let lastProgress = 0;

    // Handle upload state changes
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const currentProgress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

        // Only update if progress has changed by at least 1%
        if (currentProgress > lastProgress) {
          lastProgress = currentProgress;
          setProgress(currentProgress);

          // Start showing progress immediately for small files
          if (currentProgress === 0) {
            setProgress(1);
          }
        }
      },
      (error) => {
        // Handle specific error cases
        switch (error.code) {
          case 'storage/unauthorized':
            console.error("User doesn't have permission to access the object");
            break;
          case 'storage/canceled':
            console.error("User canceled the upload");
            break;
          case 'storage/unknown':
            console.error("Unknown error occurred, inspect error.serverResponse");
            break;
          default:
            console.error("File upload error:", error);
        }

        // Reset states on error
        setFileLoading(false);
        setProgress(0);
        throw error;
      },
      async () => {
        try {
          setProgress(99);
          // Get download URL
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          // Create file object
          const uploadedFileObj = convertFileToObject(file, file__path, downloadURL);

          // Update local state
          addFile({ ...uploadedFileObj });

          // Update Firestore
          const userDocRef = doc(
            EnvStore.getState().db,
            "organisation",
            orgId,
            "users",
            user?.uid,
            "chats",
            chat_id
          );

          await updateDoc(userDocRef, {
            files: [...files, uploadedFileObj]
          });

          // Set final states
          setProgress(100);
          setFileLoading(false);
        } catch (error) {
          console.error("Error in upload completion:", error);
          setFileLoading(false);
          setProgress(0);
          throw error;
        }
      }
    );
  } catch (error) {
    console.error("Error initiating upload:", error);
    setFileLoading(false);
    setProgress(0);
    throw error;
  }
};