import React from "react";
import { useTheme } from "../context/ThemeProvider";
import bgLight from "../assets/images/Bg_light.jpg";
import Sidebarrr from "../components/Sidebar/Sidebarrr";
import MainContent from "../components/MainContent/MainContent";
import UserAuthStore from "../store/userStore";

const AppLayout = () => {
  const { theme } = useTheme();
  const { user } = UserAuthStore.getState();

  if (user)
    return (
      <>
        <div
          className={`min-h-screen flex ${
            theme === "dark" ? "dark:bg-bgColor bg-bgColor text-gray-100" : ""
          }`}
          style={{
            backgroundImage: theme === "light" ? `url(${bgLight})` : "none",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Sidebarrr />
          <MainContent />
        </div>
      </>
    );
};

export default AppLayout;
